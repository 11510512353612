<template>
<div>
    <div class="hq_top">
        <div class="wrap">
            <a class="pointer" @click="logo('/origin/consult_schedule')">
                <img class="logo2" src="@/assets/images/logo.png">
            </a>
            <ul>
                <li><a class="pointer" @click="movePage('/notice')" :class="boldMenu('/notice')">공지사항</a></li>
                <li><a class="pointer" @click="moveCenter('/origin/consult_schedule')">
                        본원센터</a></li>
                <li><a class="pointer" @click="movePage('/account')" :class="boldMenu('/account')">
                        관리자계정</a></li>

                <li><a class="pointer" @click="movePage('/center')" :class="boldMenu('/center')">
                        센터현황</a></li>

                <li><a class="pointer" @click="movePage('/confirmConsult')" :class="boldMenu('/confirmConsult')">
                        상담일지</a></li>

                <li><a class="pointer" @click="movePage('/confirmConvention')" :class="boldMenu('/confirmConvention')">
                        협약센터승인</a></li>

                <li><a class="pointer" @click="movePage('/confirmCounselor')" :class="boldMenu('/confirmCounselor')">
                        상담사승인</a></li>
                <li><a class="pointer" @click="logout()">
                        로그아웃</a>
                </li>
            </ul>
        </div>
    </div>
    <router-view />
</div>
</template>

<script>
export default {
    data: () => ({
        toPath: ''
    }),

    watch: {
        // 관리자 페이지 내의 현재 라우터 경로 감지
        // Watch에 넣어놓은 이유는 keep-alive 사용으로 페이지 진입후 재진입할때 mount가 안되기 때문
        $route(to) {
            this.toPath = to.fullPath
        },
    },

    mounted() {
        this.toPath = this.$route.fullPath
    },

    methods: {
        // 본원센터로
        moveCenter(path) {
            sessionStorage.clear() // 검색유지조건 삭제 20210128
            this.$router.push(path)
        },

        // 현재 진입한 페이지 bold처리
        boldMenu(path) {
            if (this.toPath.includes(path)) {
                return 'bold'
            } else {
                return ''
            }
        },

        // 모든 페이지에서 로고 클릭시 상담일정 화면으로 가도록 20210316
        logo(path) {
            sessionStorage.clear()
            if(location.href.indexOf(path) > -1) {
                this.$router.go(this.$router.currentRoute)
            } else {
                this.$router.push(path)
            }
        },

        // 각 입력된 path 페이지로 이동
        movePage(path) {
            sessionStorage.clear() // 세션스토리지에 저장한 검색유지조건들 제거 20210119 과연됨?
            this.$router.push('/admin' + path)
        },

        logout() {
            this.axios.get('/api/v1/login/out')
                .then(res => {
                    alert('로그아웃 되었습니다.')
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('id')
                    sessionStorage.clear()
                    this.$router.push('/login')
                })
                .catch(err => {
                    console.log(err)
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('id')
                    sessionStorage.clear()
                    alert('세션이 만료되어 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                })
        }
    }
}
</script>

<style>
/*
body, div, ul, li, ol, dd, dt, dd, h1, h2, h3, h4, h5, h6, p, form, fieldset, legend, select, input {margin:0; padding:0;}

body, input, textarea, select, button, table {font-family:'Malgun Gothic', "돋움", Dotum, hevetica, sans-serif; font-size:12px; line-height:1.7;} 

h1, h2, h3, h4, h5, h6, {font-size:12px; font-weight:nomal;}

a {color:#666; text-decoration:none;}

a:hover, a:active {color:#6699FF;}

li, ul, ol {list-style:none;}

img { border:0;}

textarea, input {border:1px solid #d0d0d0;}

body {margin:0; padding:0;}

td {font-family:gulim; font-size:9pt; color:#736257;}*/
.point {
    font-family: gulim;
    font-size: 9pt;
    color: #FAF7E7;
}

.textbox {
    font-family: gulim;
    font-size: 9pt;
    color: #736257;
    height: 20px;
}

.msg_wrap {
    width: 230px;
}

.msg_deco_wrap {
    border-radius: 13px 13px 0 0;
    background: #efefef;
    padding: 12px 0 0 0;
}

.msg_deco {
    width: 40px;
    height: 2px;
    background: #ababab;
    border-radius: 20px;
    margin: 0 auto;
}

.msg_box {
    background: #efefef;
    padding: 8px 20px 10px 20px;
    display: block;
    height: 200px;
}

.msg_box textarea {
    width: 100%;
    height: 100%
}

.msg_send {
    background: #efefef;
    padding: 0 20px 20px 20px;
}

.msg_footer {
    background: #efefef;
    border-radius: 0 0 13px 13px;
    padding: 0 20px 20px 20px;
    text-align: center;
}
</style>
